import * as React from 'react';

import HeroImg from '../../../../src/images/__new/read_more/img1.jpg';

const HeaderContent = (props) => {
  return (
    <div className={'headerContent readMoreHeaderContent'}>
      <div className={'heroImage'}>
        <img src={HeroImg} alt={'Saxophonist'} />
      </div>
      <h1 className='title'>
        Making music as{' '}
        <span className={'titleUppercase'}>
          intensely <br /> tangible
        </span>{' '}
        as possible.
      </h1>
    </div>
  );
};

export default HeaderContent;
