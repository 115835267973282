import React, { useEffect } from 'react';

import '../scss/main.scss';

import Seo from '../components/inc/seo';

import Header from '../components/header';
import HeaderContent from '../components/readMore/headerContent';
import FirstSection from '../components/readMore/firstSection';
import SecondSection from '../components/readMore/secondSection';
import ThidrSection from '../components/readMore/thirdSection';
import FourthSection from '../components/readMore/fourthSection';
import FifthSection from '../components/readMore/fifthSection';
import EntertainSection from '../components/entertainSection';
import TopBtn from '../components/inc/topBtn';

import Footer from '../components/footer';

const OurStoryPage = () => {
  useEffect(() => {
    setTimeout(() => {
      window.AOS.init({
        duration: 500,
      });
    }, 300);
  }, []);

  return (
    <>
      <Seo />
      <Header>
        <HeaderContent />
      </Header>
      <main>
        <FirstSection />
        <SecondSection />
        <ThidrSection />
        <FourthSection />
        <FifthSection />
        <EntertainSection />
      </main>
      <Footer />
      <TopBtn />
    </>
  );
};

export default OurStoryPage;
