import * as React from 'react';

import Layout from '../../layout';

import SectionBg1 from '../../../images/__new/read_more/img2.jpg';

const FifthSection = (props) => {
  return (
    <section className={'readMoreFifithSection'}>
      <div className='firstSection'>
        <div className='bgRectangle'>
          <img
            src={SectionBg1}
            alt='Section background'
            className='sectionBg'
            loading='lazy'
          />
        </div>
        <Layout>
          <div className='textContainer'>
            <p className='sectionTitleText'>
              you don’t have to be a&nbsp;genius&nbsp;to{' '}
              <span className='yellowText'>play like a genius.</span>
            </p>
            <div className='bgTextContainer'>
              <p className='bgText'>RISE ABOVE</p>
            </div>
          </div>
        </Layout>
      </div>
    </section>
  );
};

export default FifthSection;
