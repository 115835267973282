import * as React from 'react';

import HorizontalSectionLine from '../../inc/horizontalSectionLine';
import Layout from '../../layout';

import FirstImage from '../../../images/vision/oval_photo_3.png';

const ThirdSection = (props) => {
  return (
    <div className='readMoreThirdSection'>
      <div className='bgRectangle' />
      <Layout>
        <div className='sectionTitle'>
          <h2 className='sectionTitleText'>
            No sax player could play completely <br /> unrestricted -&nbsp;
            <span className='yellowText'>until&nbsp;now.</span>
          </h2>
        </div>
        <HorizontalSectionLine />
        <div className='sectionDescription'>
          <div className='leftSectionDescription'>
            <p className='text'>
              Good news! You are not alone in this. Your struggle with your
              instrument and your doubts are real. Coltrane has experienced it.
              Charlie Parker, Lisa Simpson, Jimi Hendrix and even Beethoven.
              Every musician at some point reached the moment where he could not
              express his full talent. They all faced this challenge. They all
              failed to make progress. They all wanted to break through this
              impenetrable musical wall in order to be completely free.
            </p>
            <p className='text'>
              The problem is not you or your lack of talent. So, what is the
              problem? Your instrument.
            </p>
            <p className='text'>
              The sax is not ergonomic. That limits the way you can play this
              wonderful instrument. No musician can play his/her sax completely
              unrestricted — nobody. Musicians who play freely on their sax wear
              a strap that carries the weight of the saxophone and takes some of
              the strain off their hands while playing.
            </p>
          </div>
          <div className='rightSectionDescription'>
            <p className='text'>
              When playing with a strap, you press your sax away from your body
              with your right thumb “Thumb Hook”. This should not affect the
              resonance of your sax and at the same time improve your finger
              movement and intonation. There is only a small problem with a big
              impact on your freedom of movement: the force you have to apply to
              the Thumb Hook puts a strain on your arm muscles and finger
              joints, which in turn limits your dexterity.
            </p>
            <div className='imageContainer'>
              <img
                src={FirstImage}
                alt='Saxophonist'
                className='firstImage'
                loading='lazy'
              />
            </div>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default ThirdSection;
