import * as React from 'react';

import Layout from '../../layout';
import HorizontalSectionLine from '../../inc/horizontalSectionLine';

import FirstImage from '../../../images/vision/oval_photo_1.png';
import SecondImage from '../../../images/vision/oval_photo_2.png';

import BgImageOval from '../../../images/oval.svg';
import BgImageOvalBorder from '../../../images/oval_border.svg';
import Note1 from '../../../images/vision/note1.png';
import Note2 from '../../../images/vision/note2.png';

const FirstSection = (props) => {
  return (
    <div className={'readMoreSecondSection'}>
      <Layout>
        <div className='bgImage'>
          <img
            className='floatBase image1'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
          <img
            className='floatBase image2'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
          <img
            className='pulseBase image4'
            src={BgImageOvalBorder}
            role='presentation'
            alt='oval'
          />
          <img
            className='pulseBase image5'
            src={BgImageOvalBorder}
            role='presentation'
            alt='oval'
          />
        </div>
        <div className='sectionTitle'>
          <h2 className='sectionTitleText'>
            <span className='yellowText'>Am I</span> not good enough musician?
          </h2>
        </div>
        <HorizontalSectionLine />
        <div className='sectionDescription'>
          <div className='leftSectionDescription'>
            <p className='text'>
              You think to yourself: Okay, tomorrow is another day and tomorrow
              it will be a bit smoother and in a few weeks...
            </p>
            <p className='text'>But the next day something strange happens.</p>
            <p className='text'>
              You take your sax into your hand, loosen your lips and fingers and
              plan to commence, but you can't — everything is gone. Everything
              you fought for yesterday is gone. Your brain and your fingers have
              forgotten everything. As if you hadn't practiced at all.
            </p>
            <div className='imageContainer'>
              <div className='bgContainerImage'>
                <img
                  className='floatBase image1'
                  src={BgImageOval}
                  role='presentation'
                  alt='oval'
                />
                <img
                  className='pulseBase image2'
                  src={BgImageOvalBorder}
                  role='presentation'
                  alt='oval'
                />
                <img
                  className='pulseBase image3'
                  src={Note1}
                  role='presentation'
                  alt='oval'
                />
                <img
                  className='pulseBase image4'
                  src={Note2}
                  role='presentation'
                  alt='oval'
                />
              </div>
              <img
                src={FirstImage}
                alt='Saxophone'
                className='firstImage'
                loading='lazy'
              />
              <img
                src={SecondImage}
                alt='Saxophonist'
                className='secondImage'
                loading='lazy'
              />
            </div>
          </div>
          <div className='rightSectionDescription'>
            <p className='text'>
              Your fingers stumble over your sax in confusion. It drives you
              crazy. So, you get angry and when you get angry, you play even
              more strained and when you play even more strained, nothing works
              anymore.
            </p>
            <p className='text'>
              You would love to grab your sax with both hands and smash it —
              Kurt Cobain Style. Of course you don't do that. The sax was
              expensive and you only have one.
              <br /> There you stand in your room, in the rehearsal room and see
              your dream of a great career as a musician burst like a balloon.
              BANG!
            </p>
            <p className='text'>
              You ask yourself: what's wrong with me? Do I not have enough
              talent? Am I not a good enough musician? Am I just imagining all
              this? Is this struggle normal and do other musicians go through
              this?
            </p>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default FirstSection;
