import * as React from 'react';

import Layout from '../../layout';
import HorizontalSectionLine from '../../inc/horizontalSectionLine';

import BgImageOval from '../../../images/oval.svg';
import BgImageOvalBorder from '../../../images/oval_border.svg';

const FourthSection = (props) => {
  return (
    <div className={'readMoreFourthSection'}>
      <Layout>
        <div className='bgImage'>
          <img
            className='floatBase image1'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
          <img
            className='floatBase image2'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
          <img
            className='pulseBase image3'
            src={BgImageOvalBorder}
            role='presentation'
            alt='oval'
          />
        </div>
        <div className='sectionTitle'>
          <h2 className='sectionTitleText'>
            <span className='titleTextUnder'>The easy way to learn sax.</span>{' '}
            And to play.
          </h2>
        </div>
        <HorizontalSectionLine />
        <div className='sectionDescription'>
          <div className='leftSectionDescription'>
            <p className='text'>
              This is a well-known and widespread problem. Unfortunately, no one
              has been able or willing to solve it until now.
            </p>
            <p className='text'>
              There is always a solution for every problem. This is the solution
              for your problem and it is not even difficult to implement.
            </p>
            <p className='text'>
              With the <strong>SaxMax™</strong>, you can not only play
              completely unrestrained, you can also play pieces on the saxophone
              that are considered unplayable. And this is how it works:
            </p>
          </div>
          <div className='rightSectionDescription'>
            <p className='text'>
              The new <strong>SaxMax™</strong> is an innovative support device
              that takes the strain off your thumb when practicing and playing.
              Your right hand is completely free, so you can now use your thumb
              while playing. Not only does this allow you to develop completely
              new playing techniques, but you can now also play scores that were
              previously considered technically unplayable on the saxophone.
            </p>
            <p className='text'>
              Yes, you heard right: With the <strong>SaxMax™</strong>, you can
              now play the unplayable on your sax.
            </p>
          </div>
        </div>
      </Layout>
    </div>
  );
};

export default FourthSection;
