import * as React from 'react';

import Layout from '../../layout';
import HorizontalSectionLine from '../../inc/horizontalSectionLine';
import Btn from '../../inc/btn';

import BgImageOval from '../../../images/oval.svg';
import BgImageOvalBorder from '../../../images/oval_border.svg';

const FirstSection = (props) => {
  return (
    <div className={'readMoreFirstSection'}>
      <Layout>
        <div className='bgImage'>
          <img
            className='floatBase image1'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
          <img
            className='floatBase image2'
            src={BgImageOval}
            role='presentation'
            alt='oval'
          />
          <img
            className='pulseBase image3'
            src={BgImageOvalBorder}
            role='presentation'
            alt='oval'
          />
        </div>
        <div className='sectionTitle'>
          <h2 className='sectionTitleText'>
            How to <span className='yellowText'>play the&nbsp;unplayable?</span>
          </h2>
        </div>
        <HorizontalSectionLine />
        <div className='sectionDescription'>
          <div className='leftSectionDescription'>
            <p className='text'>
              It is so frustrating. As a musician, you want to make progress,
              you want to develop further, you want to refine your playing
              technique to become the musician you could be. As a music student,
              you want to finally learn and master your favorite songs. You want
              to be able to play like your role model. "Coltrane cool" — relaxed
              and easy. Fluent — as if your saxophone and your body were ONE.
            </p>
            <p className='text'>
              You spend hours in your room, rehearsal space, basement or
              wherever and practice until your hands are numb and your lips are
              sore — or vice versa.
            </p>
          </div>
          <div className='rightSectionDescription'>
            <p className='text'>
              After hours, you have the feeling that you are finally making
              progress. Small steps, small successes. You get through the song
              with a lot of noise, you stumble in some places, your fingers
              stagger over the flaps, your body cramps, you are highly
              concentrated, you could tell yourself that now...but in reality,
              you are only tense: — the opposite of relaxed.
            </p>
            <p className='text'>
              Flowing feels different. And at which point exactly, did the fun
              actually stop? Shouldn't playing be fun instead of frustrating?
              That's why it's called playing, right?
            </p>
          </div>
        </div>
        <Btn>Buy the SaxMax</Btn>
      </Layout>
    </div>
  );
};

export default FirstSection;
